import classes from "./IndicatorsBlock.module.css";

import { BsSearch } from "react-icons/bs";


function IndicatorsBlock() {
  return (
    <div className={classes.basicBlock}>
      <div className={classes.topBar}>
        <div className={classes.title}><b>Indicators</b></div>

        <div className={classes.createBtn}><b>Create</b></div>
      </div>


<div className={classes.searchContainer}>
      <BsSearch className={classes.searchIcon} />

      <input
        className={classes.editBox}
        type="text"
        placeholder="Search Indicators"
        name="inputfield"
      />
      </div>



      <div className={classes.whiteBoxContainer}>
        <div className={classes.whiteBlock}><b>RSI</b></div>
        <div className={classes.whiteBlock}><b>RSI (MA)</b></div>
        <div className={classes.whiteBlock}><b>MACD</b></div>
        <div className={classes.whiteBlock}><b>Bollienger</b></div>
      </div>


      <div className={classes.whiteBoxContainer2}>
        <div className={classes.whiteBlock}><b>Price</b></div>
        <div className={classes.whiteBlock}><b>Stochastic_Fast</b></div>
        <div className={classes.showallBtn}><b>Show All</b></div>

      </div>

   

    </div>
  );
}

export default IndicatorsBlock;
