import {useState} from 'react';
import classes from "./RSISelectionForm.module.css"

const RSISelectionForm=(props)=>{


  const initialFormData = {
                RSILength: props.RSI.selectedRSI.find(item => item.hasOwnProperty("RSILength")).RSILength,
                RSISource: props.RSI.selectedRSI.find(item => item.hasOwnProperty("RSISource")).RSISource,
	        MAType: props.RSI.selectedRSI.find(item => item.hasOwnProperty("MAType")).MAType,
	        MALength: props.RSI.selectedRSI.find(item => item.hasOwnProperty("MALength")).MALength
        };





  const [formData, updateFormData] = useState(initialFormData);




  const handleChangeInputHandler = (e) => {
        updateFormData({
                        ...formData,
                        [e.target.name]: e.target.value.trim(),
                });
  }


  const updateSelectedRSI = (updatedValues) => {
    props.setRSI(prevRSI => ({
        ...prevRSI, // Copy the previous state
        selectedRSI: updatedValues // Update the selectedRSI with the new values
      }));
   };



  const saveParameterHandler=()=>{

   updateSelectedRSI([
    {"RSILength": formData.RSILength},
    {"RSISource": formData.RSISource},
    {"MAType": formData.MAType},
    {"MALength": formData.MALength} // Assuming "MALength" was intended as "MALegth"
      ]);	  

   props.cancelFormHandler();	  
	  
  }




   console.log("formData: ", formData);


  const CancelHandler=()=>{
     props.cancelFormHandler();
  }


  

   let RSISource =["Open","Close"]
   let MAType = ["SMA","GMA","TMA"]



return <div className={classes.RSISelectionForm}>


   
        <div className={classes.formDiv}>


		<div className={classes.titleDiv}> <b>Set Parameters for RSI</b> </div>

                <div className={classes.InputDiv}>
	           <b>RSI Length</b>
		   <input
                     className={classes.editmobileNuBox}
                     type="text"
                     onChange={handleChangeInputHandler}
                     name="RSILength"
                     defaultValue = {formData.RSILength}

                     />
                </div>


                 <div className={classes.InputDiv}>
                   <b>RSI Source</b>
                   <select name="RSISource"  
	                   onChange={handleChangeInputHandler}
	                   type="text" 
	                   className={classes.selectBox} 
	                   defaultValue={"categoryDefault"}>
                             <option value="categoryDefault" disabled >
		                {formData.RSISource}
                             </option>
		
                           { RSISource.map((option,index)=>{
                              return <option key={index} value={option}>  {option}  </option>
                               }
                           )}

                   </select>


		</div>


                 <div className={classes.InputDiv}>
                   <b>MA Type</b>
                   <select name="MAType"
                           onChange={handleChangeInputHandler}
                           type="text"
                           className={classes.selectBox}
                           defaultValue={"categoryDefault"}>
                             <option value="categoryDefault" disabled >
		                  {formData.MAType}
                             </option>

                           { MAType.map((option,index)=>{
                              return <option key={index} value={option}>  {option}  </option>
                               }
                           )}

                   </select>


                </div>









                <div className={classes.InputDiv}>
                   <b>MA Length</b>
                   <input
                     className={classes.editmobileNuBox}
                     type="text"
                     onChange={handleChangeInputHandler}
                     name="MALength"
                     defaultValue = {formData.MALength}

                     />
                </div>










                <div className={classes.titleDiv}> 
		    <button type="button" className={classes.cancelButton} onClick={CancelHandler}><b>Cancel </b></button>
		    <button type="button" className={classes.saveButton} onClick={saveParameterHandler}><b>Save Parameters </b></button>
		</div>

        </div>

</div>


}


export default RSISelectionForm;
