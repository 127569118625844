import {useState, useEffect} from 'react';
import PreAppSecond from './PreAppSecond';
import {Route,Switch} from 'react-router-dom'
import Register from './CommonApps/Register';
import RegisterV2 from './CommonApps/Akshay/Register';

import RegisterTopper from './CommonApps/Akshay/RegisterTopper';
import RegisterStudent from './CommonApps/Akshay/RegisterStudent';
import axiosInstance from './axios';

import Website from './WebSite/Website';
import Login from './CommonApps/Akshay/Login';

const PreAppFirst=()=>{



  
  const [loggedIn, setLoggedIn] = useState(false);

  console.log("login web: ", loggedIn);


  useEffect(() =>{

      axiosInstance.get().then((res) => {
                        setLoggedIn(loggedIn=>true);
                       // console.log(res.data);
                });

  },[]);






return (

<div>

<Switch>

   <Route exact path='/registerasstudent/' >
        <RegisterStudent/>
   </Route>


   <Route exact path='/registerastopper/' >
        <RegisterTopper/>
   </Route>


   <Route  path='/' >
        <PreAppSecond setLoggedIn={setLoggedIn} loggedIn={loggedIn}/>
   </Route>

  {/*  
   <Route   path='/' >
        <Website loggedIn={loggedIn}/>
   </Route>
  */}
   



</Switch>




</div>
);
}

export default PreAppFirst;
