import classes from "./TopView.module.css";

//import { BiSolidRightArrow } from "react-icons/bi";

import { BsFillPlayFill } from "react-icons/bs";

const TopView=(props)=> {



   const addStatementHandler=()=>{

       props.addStatementHandler();    

   }


  return (
    <div className={classes.topView}>
      <div className={classes.headerView}>
        <div className={classes.create}><b>CREATE</b></div>
        <BsFillPlayFill className={classes.arrow}/>
        <div className={classes.test}><b>TEST & OPTIMISE</b></div>
      </div>

      <div className={classes.topViewInner}>
        <div className={classes.t1}><b>Building algorithmic for XAU/USD</b></div>

        <div className={classes.rightsideTopContainer}>
          <button type="button" className={classes.Bt1}><b>Add SetUp</b></button>
          <button type="button" className={classes.Bt2} onClick={addStatementHandler}><b>Add Statement</b></button>
        </div>
      </div>
    </div>
  );
}

export default TopView;
