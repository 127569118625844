import axios from 'axios';
import {useState, useEffect, useRef} from 'react';
import classes  from './PayForm.module.css';
import { GiCheckMark } from "react-icons/gi";
import { checkifuserexist, bookslotbystudent } from '../../../CommonApps/AllAPICalls';
import FadeLoader from "react-spinners/BarLoader";
import {Logout} from '../../../CommonApps/Logout';
import flag from "./flag.jpeg";
import { v4 as uuidv4 } from 'uuid';
import sha256 from "crypto-js/sha256";
import { FaRupeeSign } from "react-icons/fa";

//import { useRouter } from "next/navigation";
//import { redirect } from "next/navigation";

import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom';

//import { useRouter } from "next/router";

const useFocus = () => {
    const htmlElRef = useRef(null)
    const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()}
    return [ htmlElRef, setFocus ]
}


const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};



const BookingForm=(props)=>{

    const axios = require('axios');


    let color="var(--themeColor)";
    const inActiveColor = {color: "grey",
    backgroundColor:"lightgrey",
    borderStyle:"none",
    borderWidth:"1px"}

    const activeColor = {color: "white",
    backgroundColor:"var(--themeColor)",
    borderStyle:"none",
    borderWidth:"1px"}


  const [bookSlotButtonColor, setBookSlotButtonColor] = useState(inActiveColor);


  const history = useHistory();

  const initialFormData = {
                name:"",
                phoneno:"",
	        amount:"",
	        muid:""
        };

   const [formData, updateFormData] = useState(initialFormData);

   const [userExists, setUserExists] = useState(false);

   useEffect(()=>{

          let userinput = formData.inputfield;
          checkifuserexist({setUserExists, userinput });

       },[formData.inputfield]);


    const handleChangeInputHandler=(event)=>{

     updateFormData({
                        ...formData,
                        [event.target.name]: event.target.value.trim(),
                });

    }


   const [bookingStatus, setBookingStatus] = useState("notBooked");

    const handleSubmit=(e)=>{
      e.preventDefault();

      if(formData.phoneno === ""){
         alert("Please enter phoneno");
	 return null;    
      }



      let slotId =props.oneSlot.id;
      let phoneno = "+91"+formData.phoneno;
     
      //console.log("phoneno: ", phoneno);	    

      //setBookingStatus(bookingStatus=>"booking")
      //bookslotbystudent({slotId,phoneno, setBookingStatus});
    }


    const closeFormHandler=()=>{
      props.close();
    }

   const sendOTPHandler=()=>{


   }



   
  const options = {
      method: 'post',
      url: 'https://api-preprod.phonepe.com/apis/pg-sandbox/pg/v1/pay',
       headers: {
          accept: 'text/plain',
          'Content-Type': 'application/json',
				},
          data: {}};

    const PayInitiateHandler1=()=>{

        axios
        .request(options)
        .then(function (response) {
         console.log(response.data);
      })
      .catch(function (error) {
        console.error(error);
      });


    }

    const [submitStatus, setSubmitStatus] = useState("notStarted");




    const PayInitiateHandler=async()=>{

         const rate = (Number(props.topperProfile.hourlyrate))*100;

         const transactionid = "TR-"+uuidv4().toString(36).slice(-6);

	 const phoneNumber = "+91"+formData.phoneno;   
         
	 const redirectURL = "https://hellotoppers.com/api/paymentstatus/"+String(phoneNumber)+"/"+String(props.oneSlot.id)+"/";   

         //const redirectURL = "http://127.0.0.1:8000/api/paymentstatus/"+String(phoneNumber)+"/"+String(props.oneSlot.id)+"/";

         

         let testCred={"merchantId":"PGTESTPAYUAT","apiKey":"099eb0cd-02cf-4e2a-8aca-3e6c6aff0399","payURL":"https://api-preprod.phonepe.com/apis/pg-sandbox/pg/v1/pay" }


	 let prodCred = {"merchantId":"M2284KCBRJKOP","apiKey":"2f031cba-3c25-4c5f-9f8a-f92a8bf7d459","payURL":"https://api.phonepe.com/apis/hermes/pg/v1/pay" }   

         const credentials = prodCred;


	  //"PGTESTPAYUAT"//M2284KCBRJKOP
	 const payload = {
         "merchantId": credentials.merchantId,
         "merchantTransactionId": transactionid,
         "merchantUserId": "MUID123",
         "amount": Number(rate),
         "redirectUrl": redirectURL,
         "redirectMode": "POST",
         "callbackUrl": redirectURL,
         "mobileNumber": String(formData.phoneno),
         "paymentInstrument": {
                 "type": "PAY_PAGE"
         }
      }




      const dataPayload = JSON.stringify(payload);
      console.log(dataPayload);

      const dataBase64 = Buffer.from(dataPayload).toString("base64");
      console.log(dataBase64);


      const fullURL = dataBase64 + "/pg/v1/pay" + credentials.apiKey;
      const dataSha256 = sha256(fullURL);

      const checksum = dataSha256 + "###" + "1";
      console.log("c====",checksum);

      const UAT_PAY_API_URL = "https://api-preprod.phonepe.com/apis/pg-sandbox/pg/v1/pay";


      //const UAT_PAY_API_URL = "https://api.phonepe.com/apis/hermes/pg/v1/pay"

      const response =await  axios.post(
        credentials.payURL,
         {
          request: dataBase64,
         },
         {
           headers: {
               accept: "application/json",
               "Content-Type": "application/json",
               "X-VERIFY": checksum,
              },
         }
      );


      console.log("response: ", response);

      const redirect=response.data.data.instrumentResponse.redirectInfo.url;
        window.open(redirect,"__blank");
    }



   const loginHandler=()=>{
     Logout();	   
     history.push("/app/home/slots"); 
   }

   console.log("formData: ", formData);




return <div className={classes.bookingForm}>


   <div className={classes.formBox}>

	    <div className={classes.formContentBox}>
             <div className={classes.closeButtonDiv}> 
	         <button type="button" className={classes.closeButton} onClick={closeFormHandler}> X</button>	
             </div>

             <div ><b> Proceed to Payment </b> </div>

            {/*		
    	     <div className={classes.mainContainer}>	
                 <input
                   className={classes.editmobileNuBox}
                   type="text"
                   placeholder="Name"
                   onChange={handleChangeInputHandler}
                   name="name"
                   defaultValue=""
                   />
	     </div>	

             */}

              <div className={classes.mainContainer}>

                 <div className={classes.flagcode}>
                     <img className={classes.flagImg} src={flag} alt="logo"/>
                     <div className={classes.countryCode}> +91 </div>
                 </div>
                 <input
                     className={classes.editmobileNuBox}
                     type="number"
                     placeholder=" Mobile Number.."
                     onChange={handleChangeInputHandler}
                     name="phoneno"
                     />
                 </div>

                 <div style={{display:"flex", justifyContent:"center",alignItems:"center"}}>
                 
                  <h2> <FaRupeeSign/> {props.topperProfile.hourlyrate} </h2>
		</div>








          
            { bookingStatus ==="notBooked" &&
                <button className={classes.bookSlotButton} 
		        type="button" 
		        onClick={PayInitiateHandler} 
		        style={formData.phoneno.length === 10 ? activeColor : inActiveColor }
                        disabled = {formData.phoneno.length === 10 ? false : true }
			>
			  Pay Now
		</button>
            }
            




            <div className={classes.checkBoxTermsAndConditions}>
              <input type="checkbox" defaultChecked/>
                By continuing here you are agreeing to our terms of service and privacy policy.
            </div>     









            { bookingStatus ==="booking" &&                
	        <button className={classes.bookSlotButton} type="button"  disabled={true}  >Booking...</button>
            }




             { bookingStatus ==="booking" && <>
                <FadeLoader color={color} loading={true} css={override} size={50}   />
                    </>
             }


            { bookingStatus ==="booked" &&
               <button className={classes.bookSlotButton} type="button"  disabled={true}  >Booked <GiCheckMark/> </button>
            }


            { bookingStatus ==="booked" &&
                <button type="button" className={classes.loginToMeetingLinkButton} onClick={loginHandler}>			     
		   Login to view meeting link
	        </button>		    
	    }









       </div>





        



   </div>

</div>

}


export default BookingForm;
