import React,{useState,useEffect} from 'react';
import App from './App';
import Login from './CommonApps/Akshay/NewAQLogin';
import axiosInstance from './axios';




const PreAppSecond=(props)=>{

  //const [loggedIn, setLoggedIn] = useState(false);
  
  //console.log("props.loggedIn: ", props.loggedIn); 

  //useEffect(() =>{

  //    axiosInstance.get().then((res) => {
  //                      props.setLoggedIn(loggedIn=>true);
                       // console.log(res.data);
  //              });

  //},[]);
  
  
  if (! props.loggedIn){ return <Login setLoggedIn={props.setLoggedIn} loadedUsername="None"/>}


return (
        
<App/>
      
);

}

export default PreAppSecond;
