import classes from "./BasicBlock.module.css"



function BasicBlock() {
    return (

        <div className={classes.basicBlock}>
        <div className={classes.title}><b>Basic Block</b></div>

        <div className={classes.whiteBoxContainer}>
          <div className={classes.whiteBlock}><b>If</b></div>
          <div className={classes.whiteBlock}><b>Unless</b></div>
          <div className={classes.whiteBlock}><b>Then</b></div>
          <div className={classes.whiteBlock}><b>Timeframe</b></div>

        </div>

        <div className={classes.whiteBoxContainer2}>
          <div className={classes.whiteBlock}><b>Duration</b></div>
          <div className={classes.whiteBlock}><b>When</b></div>

        </div>

      </div>

    )
}

export default BasicBlock;
