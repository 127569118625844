import React, { useState, useEffect, CSSProperties } from "react";
import LoginHeader from "../LoginHeader";
import classes from "./Login.module.css";
//import {OptionField} from './OptionField';
import {createaccountnew, checkuserexist, checkifuserexist, createaccount} from '../AllAPICalls';
import FadeLoader from "react-spinners/FadeLoader";
import {BsCheckLg } from 'react-icons/bs';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

import { useHistory } from "react-router-dom";  
import cover from "./a_Register_as_a_student banner.jpg";
import { BsGoogle, BsFacebook, BsLinkedin } from "react-icons/bs";
import { FcGoogle } from "react-icons/fc";
import Login from './Login';
import basewebURL from '../../basewebURL';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import Logo from '../Logo';
import axiosInstance from '../../axios';
import img1 from './registerimg.jpg';
import { FaHome } from "react-icons/fa";

import flag from './flag.jpeg';

import topperlogo from './topperlogo.png';

import apple from "./apple.jpg";
import google from "./google.jpg";


const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};







const AQLogin=(props)=>{

  

  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };




  const initialFormData = {
                username:'',
	        password:'',
        };





  const [formData, updateFormData] = useState(initialFormData);	

   const handleChangeInputHandler=(event)=>{


     updateFormData({
                        ...formData,
                        [event.target.name]: event.target.value.trim(),
                });

    }


  


   const handleSubmit = (e) => {
                e.preventDefault();


               if( formData.username === "" && formData.password === ""

                 ){alert("Fill username and password ");}




                 axiosInstance.post(`customtoken/`, {login_identifier: formData.username, password: formData.password,})
                        .then((res) => {
                                localStorage.setItem('access_token', res.data.access);
                                localStorage.setItem('refresh_token', res.data.refresh);
                                axiosInstance.defaults.headers['Authorization'] =
                                        'JWT ' + localStorage.getItem('access_token');
                                props.setLoggedIn(true);
                                let location_=window.location.href;


                                if(location_.includes("app/home/dashboard")){

                                  //let redirectlink = basewebURL+"/app/home/dashboard";
				  let redirectlink = "http://localhost:3000/app/home/dashboard";
                                  window.location.href= location_;

                                }





                        });
        }









return (

<div className={classes.mainDiv}>



     <div className={classes.subMainDiv}>
	    
            {/* <div className={classes.image}>
              	      
              <img className={classes.coverImg} src={cover} alt="logo"></img>
	      
            </div> */}
            

        <div className={classes.parentdiv}>
          <div className={classes.topBar}>
	    <div className={classes.logoDiv}>
		  {/* AnyQuant  */}
		  {/*<img src={topperlogo} className={classes.logoImage}/> */}
	    </div>
            <div className={classes.loginTitle}> Sign In  </div>
          </div>


          <div className={classes.userNameTxt}>Username</div>

          <div className={classes.midContent}>

              {/* <div className={classes.loginTitleText}>Type Your Mob Number to Register</div> */}

              <div className={classes.mainContainer}>
              <input
                     className={classes.editmobileNuBox}
                     type="text"
	             onChange={handleChangeInputHandler}
	             name="username"
                     defaultValue = {formData.username}

                     />  



                 {/* <div className={classes.flagcode}>		     
                     <img className={classes.flagImg} src={flag} alt="logo"/> 
                     <div className={classes.countryCode}> +44 </div>
	         </div>   
                 <input
                     className={classes.editmobileNuBox}
                     type="number"
                     placeholder=" Mobile Number.."
	             onChange={handleChangeInputHandler}
	             name="phoneno"
                     />         		       */}
              </div>


              <div className={classes.passwordTxt}>
                <div className={classes.password}>Password</div>
                <div className={classes.forgetPassword}>Forget Password?</div>
              </div>

              <div className={classes.mainContainer}>
              <input
                     className={classes.editmobileNuBox}
                    //  type="number"
                    //  placeholder=" Mobile Number.."
	             onChange={handleChangeInputHandler}
	             name="password"
                     type={showPassword ? 'text' : 'password'}
                     value={formData.password}
        
                     />  
                      <button className={classes.showPassword} onClick={togglePasswordVisibility}>
        {showPassword ? <FaEyeSlash className={classes.showPasswordIcon}/> : <FaEye className={classes.showPasswordIcon}/>}
      </button>



                 {/* <div className={classes.flagcode}>		     
                     <img className={classes.flagImg} src={flag} alt="logo"/> 
                     <div className={classes.countryCode}> +44 </div>
	         </div>   
                 <input
                     className={classes.editmobileNuBox}
                     type="number"
                     placeholder=" Mobile Number.."
	             onChange={handleChangeInputHandler}
	             name="phoneno"
                     />         		       */}
              </div>


              <button className={classes.createBtn} 
	              type="button" 
	              onClick={handleSubmit} 
		      >
		  Sign In 
	      </button>

              <div className={classes.alreadyRegisteredDiv}> 
	          <button className={classes.alreadyloginnow} type="button" > or sign in with </button>
	      </div>




        <div className={classes.checkBoxTermsAndConditions}>

                    <button className={classes.googleBtnDiv}><img className={classes.googleBtn} src="https://img.icons8.com/color/48/000000/google-logo"></img><span className={classes.googleText}> Google</span></button>
                    <button  className={classes.appleBtnDiv}><img className={classes.appleBtn} src="https://upload.wikimedia.org/wikipedia/commons/f/fa/Apple_logo_black.svg"/><span className={classes.appleText}>Apple</span>  </button>

        {/* <input type="checkbox" defaultChecked/> */}
        
        </div>     

          </div>

        </div>
      </div>








</div>

);

}

export default AQLogin;
