import {useState} from 'react';
import classes from "./StatementView.module.css";
import { HiDotsVertical } from "react-icons/hi";
import { MdModeEdit } from "react-icons/md";
import { TiArrowSortedDown } from "react-icons/ti";


import RSISelectionForm from './RSISelectionForm';
import RSIMASelectionForm from './RSIMASelectionForm';


const OneStatement=(props)=>{

  const [showDropDown, setShowDropDown] = useState(false);

  const showDropDownHandler=()=>{

   setShowDropDown(showDropDown=>!showDropDown);

  }

  const findId = (statements) => {
    for (let statement of statements) {
      if (statement.id) {
        return statement.id;
      }
    }
    return "ID not found";
  }

  let statement_ = props.oneStatement;
  let statementId_ = findId(statement_);

  const DeleteStatementHandler=()=>{
    //let statementId = props.oneStatement[0].id; 
    let statement = props.oneStatement;
    let statementId = findId(statement);
    console.log("statementId: ", statementId);
    props.removeStatementById(statementId)
  }

  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  const handleInputChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    const filteredResults = listObjects.filter(item =>
      item.toLowerCase().includes(query.toLowerCase())
    );
    setSearchResults(filteredResults);
  };

  const handleRemoveLastItem = () => {
      // Create a new array without the last element
      const updatedSelectedItems = selectedItems.slice(0, -1);
      // Update the state with the new array
      setSelectedItems(updatedSelectedItems);
  };


  const handleKeyDown = (event) => {
    if (event.key === "Backspace" && searchQuery === "") {

       handleRemoveLastItem();

    }
};



  //const handleSelectItem = (item) => {
  //  const newSelectedItems = [...selectedItems, item];
  //  setSelectedItems(newSelectedItems);
  //};

  const handleSelectItem = (item) => {
     // Check if the item already exists in selectedItems array
     if (!selectedItems.includes(item)) {
        // If it doesn't exist, add it to the array
        const newSelectedItems = [...selectedItems, item];
        setSelectedItems(newSelectedItems);
    }
  };


  const [showTimeDropDown, setShowTimeDropDown] = useState(false); 

  const showTimeDropDownHandler=()=>{

     setShowTimeDropDown(showTimeDropDown=>!showTimeDropDown);

  }

  const selectATimeframe=(timeValue)=>{

    setTimeframe({ ...Timeframe, selectedTime: timeValue });	  

  }


  const [showRSISelectionForm, setRSISelectionForm] = useState(false);

  const [showRSIMASelectionForm, setRSIMASelectionForm] = useState(false);




  const selectRSIParsHandler=()=>{

    setRSISelectionForm(showRSISelectionForm=>!showRSISelectionForm);

  }

  const selectRSIMAParsHandler=()=>{

    setRSIMASelectionForm(showRSIMASelectionForm=>!showRSIMASelectionForm);

  }





  const cancelFormHandler=()=>{

   setRSISelectionForm(false);

  }


  const cancelRSIMAFormHandler=()=>{
   setRSIMASelectionForm(false);

  }





   let listObjects = ["If","Unless","When","Then","Timeframe","Duration","RSI", "RSIMA","MACD","Price","Stocastic_Fast","Bollinger","Crossing Up","Crossing Down","Greater Than","Less Than","Long","Short","Wait","Manage","Close","Cancel","No Trade","Reverse"]


   const [If,setIf] =useState( {"If":"If"});
   const [Timeframe, setTimeframe] = useState({"Timeframe":[ "1","5","15","30"],"selectedTime":"5"});
   const [RSI, setRSI] = useState({"RSI": [{"RSILength":["5","14","20"]},{"RSISource":["Close","Open"]},{"MAType":["SMA","GMA","TA"]},{"MALegth":["12","14","34"]}], "selectedRSI":[{"RSILength":"14"},{"RSISource":"Close"},{"MAType":"SMA"},{"MALength":"14"}]});

   const [RSIMA, setRSIMA] = useState({"RSI": [{"RSILength":["5","14","20"]},{"RSISource":["Close","Open"]},{"MAType":["SMA","GMA","TA"]},{"MALegth":["12","14","34"]}], "selectedRSI":[{"RSILength":"14"},{"RSISource":"Close"},{"MAType":"SMA"},{"MALength":"14"}]});

    




   //let objectDetail = {"If":"If","Timeframe":[ "3","1","5","15","30"],"RSI":[{"RSILength":"14","RSISource":"Close"},{}]}

   
   console.log("searchItems: ", selectedItems);








return  <div className={classes.statement2View}>
        <div className={classes.sTopView}>
          <div className={classes.stTitle}><b>Statement {statementId_}</b></div>
         
          <div className={classes.dropdownParent}>		
          <button type="button" onClick={showDropDownHandler} className={classes.threeDotsButton}>		
            <HiDotsVertical className={classes.menuIcon} />
	  </button>

	  { showDropDown && 
             <div className={classes.dropDownMenu}>
	        <button type="button" className={classes.deleteButton} onClick={DeleteStatementHandler}> Delete </button>	
	     </div>	
            }

            { showRSISelectionForm && 
                <RSISelectionForm cancelFormHandler={cancelFormHandler}
		                  RSI={RSI}
		                  setRSI={setRSI}
			          />
            }
 
            { showRSIMASelectionForm &&
                <RSIMASelectionForm cancelFormHandler={cancelRSIMAFormHandler}
                                  RSI={RSIMA}
                                  setRSI={setRSIMA}
                                  />
            }





          </div>

        </div>

        <div className={classes.MainView}>

         <div className={classes.statementNinput}>
   
	  <div className={classes.statementDiv}>	
              {selectedItems.map((item, index) => {

		        console.log("item: ", item);
                        if (item === "If") {
                                 return <div key={index} className={classes.whiteBlock}><b>If </b></div>;
                        }else if(item === "When") { 

                            return <div key={index} className={classes.whiteBlock}><b>When </b></div>;

			} 

                        else if(item === "Unless") {

                            return <div key={index} className={classes.whiteBlock}><b>Unless </b></div>;

                        } 


                       else if(item === "Then") {

                            return <div key={index} className={classes.whiteBlock}><b>Then </b></div>;

                        } 

                       else if(item === "Crossing Up") {

                            return <div key={index} className={classes.whiteBlock}><b>Crossing Up </b></div>;

                        } 
 
		       else if(item === "Crossing Down") {

                            return <div key={index} className={classes.whiteBlock}><b>Crossing Down </b></div>;

                        } 
 
		       else if(item === "Greater Than") {

                            return <div key={index} className={classes.whiteBlock}><b>Greater Than </b></div>;

                        } 

		        else if(item === "Less Than") {

                            return <div key={index} className={classes.whiteBlock}><b>Less Than </b></div>;

                        } 

                        else if(item === "Long") {

                            return <div key={index} className={classes.whiteBlock}><b>Long </b></div>;

                        } 

		        else if(item === "Short") {

                            return <div key={index} className={classes.whiteBlock}><b>Short </b></div>;

                        } 


		        else if(item === "Wait") {

                            return <div key={index} className={classes.whiteBlock}><b>Wait </b></div>;

                        } 


                       else if(item === "Close") {

                            return <div key={index} className={classes.whiteBlock}><b>Close </b></div>;

                        } 

		        else if(item === "Cancel") {

                            return <div key={index} className={classes.whiteBlock}><b>Cancel</b></div>;

                        } 


		       else if(item === "No Trade") {

                            return <div key={index} className={classes.whiteBlock}><b>No Trade </b></div>;

                        } 

		       else if(item === "Reverse") {

                            return <div key={index} className={classes.whiteBlock}><b>Reverse </b></div>;

                        } 



		      else if (item === "Timeframe") {

				 
                                 return (
                                         <div key={index} className={classes.timeFrame} onClick={showTimeDropDownHandler}>
                                             <div className={classes.topText}>Timeframe</div>
                                             <div className={classes.titleFrame}>{Timeframe.selectedTime} mins</div>
                                             <TiArrowSortedDown className={classes.ArrowIcon} />

                                             { showTimeDropDown &&
                                              <div className={classes.detailsFrame}>

                                                  { Timeframe.Timeframe.map((timevalue, indext)=>{

                                                      return <div className={classes.parValueTime} key={indext} onClick={()=>selectATimeframe(timevalue)}><b> {timevalue} mins</b></div>
						      }
						    )
						  }

                                              </div>
                                             }





                                         </div>
                                        );
                       }



                       else if (item === "RSI") {


                                        return (<div className={classes.timeFrameRSI} onClick={selectRSIParsHandler}>
                                                    <div className={classes.topText}>Indicator</div>
                                                    <div className={classes.titleFrame}>RSI</div>
                                                    <MdModeEdit className={classes.ArrowIcon} />

                                                    <div className={classes.detailsFrame}>
                                                         { RSI.selectedRSI.map((RSIPar, RSIParIndex)=>{

                                                             return <div className={classes.parValue} key={RSIParIndex}>
									 
                                                                      {Object.entries(RSIPar).map(([key, value]) => (
                                                                                <div key={key}>
                                                                                    <b>{key}:{" "} {value}</b>
                                                                                </div>
                                                                       ))}



								    </div>

							   })
							 }

                                                     </div>

                                                </div>
                                              );


		       }


                       else if (item === "RSIMA") {

                                return (<div className={classes.timeFrameRSI} onClick={selectRSIMAParsHandler}>
                                                    <div className={classes.topText}>Indicator</div>
                                                    <div className={classes.titleFrame}>RSI (MA)</div>
                                                    <MdModeEdit className={classes.ArrowIcon} />

                                                    <div className={classes.detailsFrame}>
                                                         { RSIMA.selectedRSI.map((RSIPar, RSIParIndex)=>{

                                                             return <div className={classes.parValue} key={RSIParIndex}>

                                                                      {Object.entries(RSIPar).map(([key, value]) => (
                                                                                <div key={key}>
                                                                                    <b>{key}:{" "} {value}</b>
                                                                                </div>
                                                                       ))}



                                                                    </div>

                                                           })
                                                         }

                                                     </div>

                                                </div>
                                              );

                       }



                       return null;
              })}
                       
          </div>



	  <input
            className={classes.componenet}
            type="text"
            placeholder="Start Typing A component Name"
            name="inputfield"
	    onChange={handleInputChange}
	    value={searchQuery}
	    onKeyDown={handleKeyDown}
          />

	 </div>	

         <div className={classes.searchResults}>
             {searchResults.map((item, index) => (
               <div key={index} onClick={() => handleSelectItem(item)} className={classes.whiteBlock}>
                    <b>{item}</b>
               </div>
             ))}
         </div>

		{/*	
          <ul>
        {searchResults.map((item, index) => (
          <li key={index} onClick={() => handleSelectItem(item)}>
            {item}
          </li>
        ))}
      </ul>
      <h2>Selected Items</h2>
      <ul>
        {selectedItems.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>

         */}

        </div>


       {/*

        <div className={classes.MainView}>
          <div className={classes.statmentDetails}>
            <div className={classes.whiteBlock}><b>if</b></div>

            <div className={classes.timeFrame}>
              <div className={classes.topText}>Timeframe</div>
              <div className={classes.titleFrame}>3 Hours</div>
              <TiArrowSortedDown className={classes.ArrowIcon} />
            </div>


            <div className={classes.timeFrame}>
              <div className={classes.topText}>Indicator</div>
              <div className={classes.titleFrame}>RSI</div>
              <MdModeEdit className={classes.ArrowIcon} />

              <div className={classes.detailsFrame}>
                <div className={classes.details1}>Rsi lenagth : 14</div>
                <div className={classes.details2}>Rsi Source : Close</div>
                <div className={classes.details3}>MA Type : SMA</div>
                <div className={classes.details3}>MA length : 14</div>
              </div>
            </div>
          </div>

          <div className={classes.statmentDetails}>
            <div className={classes.whiteBlock}><b>CrossingUp</b></div>


            <div className={classes.timeFrame}>
              <div className={classes.topText}>Value</div>
              <div className={classes.titleFrame}>RSI MA</div>
              <MdModeEdit className={classes.ArrowIcon} />

              <div className={classes.detailsFrame}>
                <div className={classes.details1}>No Of Periods : 4</div>
                <div className={classes.details2}>From T -1</div>
                <div className={classes.details3}>Include : YES</div>
              </div>
            </div>
          </div>

          <div className={classes.statmentDetails}>
            <div className={classes.whiteBlock}><b>Long</b></div>

            <div className={classes.timeFrame}>
              <div className={classes.topText}>Stop Loss</div>
              <div className={classes.titleFrame}>From Low</div>
              <TiArrowSortedDown className={classes.ArrowIcon} />
            </div>


            <div className={classes.timeFrame}>
              <div className={classes.topText}>TakeProfit</div>
              <div className={classes.titleFrame}>From Entry</div>
              <TiArrowSortedDown className={classes.ArrowIcon} />
            </div>
          </div>

          <input
            className={classes.componenet}
            type="text"
            placeholder="Start Typing A componenet Name"
            name="inputfield"
          />
        </div>
        */}



      </div>

}

export default OneStatement;
