import classes from "./ActionBlock.module.css"

function ActionBlock() {
    return (

      <div className={classes.basicBlock}>
      <div className={classes.title}><b>Actions</b></div>

      <div className={classes.whiteBoxContainer}>
        <div className={classes.whiteBlock}><b>Long</b></div>
        <div className={classes.whiteBlock}><b>Short</b></div>
        <div className={classes.whiteBlock}><b>Wait</b></div>

      </div>

      {/* <div className={classes.whiteBoxContainer2}>
        <div className={classes.whiteBlock}>Duration</div>
        <div className={classes.whiteBlock}>When</div>

      </div> */}

    </div>

    )
}

export default ActionBlock;
