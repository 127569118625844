import classes from "./TradeBlock.module.css"

function TradeBlock() {
    return (

      <div className={classes.basicBlock}>
      <div className={classes.title}><b>Trade Management</b></div>

      <div className={classes.whiteBoxContainer}>
        <div className={classes.whiteBlock}><b>Manage</b></div>
        <div className={classes.whiteBlock}><b>Close</b></div>
        <div className={classes.whiteBlock}><b>Cancel</b></div>
        <div className={classes.whiteBlock}><b>No Trade</b></div>

      </div>

      <div className={classes.whiteBoxContainer2}>
        <div className={classes.whiteBlock}><b>Reverse</b></div>

      </div>

    </div>

    )
}

export default TradeBlock;
