import {useState} from 'react';
import classes from "./App.module.css";
//import "typeface-roboto";
import BasicBlock from "./BasicBlock";
import IndicatorsBlock from "./IndicatorsBlock";
import BehavioursBlock from "./BehavioursBlock";
import ActionBlock from "./ActionBlock";
import TradeBlock from "./TradeBlock";
import BottomBar from "./BottomBar";
import TopView from "./TopView";
import StatementView from "./StatementView";

const App=()=> {


      
      

  const [statements, setStatements] = useState([]);
  
  const [sid, setSid] = useState(1);

  let defaultStatement = [ [{"id":sid},{"BasicBlock":"If"},{"Indicator":"Timeframe"} ]]

  const addStatementHandler=()=>{
    const updatedStatements = [...statements, ...defaultStatement];  
    setStatements(updatedStatements);
    setSid(sid=>sid+1);
  }






  const removeStatementById=(statementId)=>{
      console.log("statementId: ", statementId); 
      setStatements(prevStatements => (
      prevStatements.filter(statement => (
        !statement.some(obj => obj.id === statementId)
      ))
    ));


  }




  return (
    <div className={classes.mainBox}>

      <div className={classes.parentBox}>

        <div className={classes.mainView}>
          <div className={classes.subViewContainer}>
            <TopView addStatementHandler={addStatementHandler}/>

            <StatementView statements={statements}
	                   removeStatementById={removeStatementById}
	                   />
          </div>

          <BottomBar />
        </div>

        <div className={classes.componentsView}>
          <div className={classes.componentsTitle}><b>Components</b></div>

          <div className={classes.scrollView}>
            <BasicBlock />
            <IndicatorsBlock />
            <BehavioursBlock />
            <ActionBlock />
            <TradeBlock />
            
          </div>
        </div>

      </div>
    </div>
  );
}

export default App;
